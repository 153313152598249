import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "50",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M9.21376 42.0803L38.0835 42.0803C44.6648 42.0803 50 36.7451 50 30.1638C50 23.5826 44.6648 18.2474 38.0835 18.2474C37.7318 18.2474 37.3836 18.2626 37.0395 18.2925C34.7071 12.2251 28.8241 7.91895 21.9353 7.91895C13.5306 7.91895 6.62301 14.3288 5.83427 22.5266C2.38272 24.185 0 27.7138 0 31.799C0 37.1168 4.0372 41.4915 9.21376 42.0255V42.0803ZM27.7487 20.036L27.2148 22.287L21.9355 17.2839L28.8942 15.2061L28.4463 17.0943C32.5314 18.519 35.4625 22.4052 35.4625 26.9761C35.4625 31.0509 33.1328 34.5789 29.7396 36.3057L28.379 33.632C30.8056 32.3971 32.4625 29.8788 32.4625 26.9761C32.4625 23.8253 30.5099 21.1305 27.7487 20.036ZM17.5371 26.9761C17.5371 24.0539 19.2164 21.5212 21.6695 20.2955L20.3286 17.6118C16.8981 19.3259 14.5371 22.8739 14.5371 26.9761C14.5371 31.7438 17.7261 35.7667 22.0874 37.0281L21.553 39.0329L28.5664 37.1481L23.4274 32.001L22.8605 34.1276C19.7815 33.2079 17.5371 30.3541 17.5371 26.9761Z",
      fill: "#212529"
    }, null, -1)
  ])))
}
export default { render: render }